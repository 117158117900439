<template>
  <LxpMobileHeader v-if="isMobile" title="배지 보관함">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBackFunc('MyPageMain')">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <!--    <template v-slot:right>-->
    <!--      <div class="util util-search">-->
    <!--        <a href="javascript:" class="util-actions util-actions-search">-->
    <!--          <i class="icon-search"></i>-->
    <!--        </a>-->
    <!--      </div>-->
    <!--    </template>-->
  </LxpMobileHeader>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-mypage">
    <lxp-main-header v-if="isMobile">
      <template v-slot:action>
        <HistoryYearSelector :show-select-all="false" :yearCnt="2" v-model="yearStr"/>
      </template>
    </lxp-main-header>
    <lxp-main-header v-else :show-back="true" :show-custom-title="true" title="배지 보관함">
      <template v-slot:action>
        <HistoryYearSelector :show-select-all="false" :yearCnt="2" v-model="yearStr"/>
      </template>
    </lxp-main-header>
    <div class="main-content min-component">
      <div class="badge-list-container">
        <div class="list-top">

          <div class="top-column">
            <div class="list-sort">
              <div class="sort">
                <div class="kb-form-dropdown" :class="{'is-active': toggle}">
                  <button class="kb-form-dropdown-btn"  @click.stop="toggle=!toggle"><strong class="text">{{ selectedDivision }}</strong><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item">
                          <a href="javascript:" class="dropdown-option-link" :class="{ 'is-active' : division === ''}" @click.stop="handleSelectDivision('')">
                            <span class="dropdown-option-text">전체</span></a></li>
                        <li class="dropdown-option-item">
                          <a href="javascript:" class="dropdown-option-link" :class="{ 'is-active' : division === 'ssl'}" @click.stop="handleSelectDivision('ssl')">
                            <span class="dropdown-option-text">SSL</span></a></li>
                        <li class="dropdown-option-item">
                          <a href="javascript:" class="dropdown-option-link" :class="{ 'is-active' : division === 'hrdcloud'}" @click.stop="handleSelectDivision('hrdcloud')">
                            <span class="dropdown-option-text">HRD 클라우드</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-column">
            <div class="subtext"><strong>{{ paging.totalCount }}</strong> / {{sslHrdBadges.length}}개</div>
          </div>
        </div>
        <template v-if="!isLoading">
          <div v-if="isMobile" class="badge-list-wrapper padding-component">
            <ul class="badge-list" :class="{ 'kb-badge-box-list' : isMobile }">
              <li v-for="(item, idx) in sslHrdBadges" class="badge-item" :key="idx">
                <IntegratedBadge
                    :division="item.division"
                    :badges="item"
                    :badge-ty-cd-dcd="item.badgeTyCdDcd"
                    :is-active="checkActive(item.badgeTyCdDcd)"
                    :is-new="checkNew(item.badgeTyCdDcd)"
                    :year="yearStr"
                    :click-func="showBadgeModal"
                    :hiddenYn="item.hiddenYn"
                />
              </li>
            </ul>
          </div>
          <ul v-else class="badge-list" :class="{ 'kb-badge-box-list' : isMobile }">
            <li v-for="(item, idx) in sslHrdBadges" class="badge-item" :key="idx">
              <IntegratedBadge
                  :division="item.division"
                  :badges="item"
                  :badge-ty-cd-dcd="item.badgeTyCdDcd"
                  :is-active="checkActive(item.badgeTyCdDcd)"
                  :is-new="checkNew(item.badgeTyCdDcd)"
                  :year="yearStr"
                  :hiddenYn="item.hiddenYn"
              />
            </li>
          </ul>
          <!--          <CommonPaginationFront v-if="paging" :paging="paging" :page-func="pagingFunc" />-->
        </template>
        <template v-else-if="isLoading">
          <LoadingDiv v-if="isLoading" v-model="isLoading" />
        </template>
      </div>
    </div>
    <SSLBadgeViewModal
        v-model="badgeModal"
        :selected-badge="selectedBadge"
        :type="selectedBadgeType"
    />
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {useRoute} from "vue-router/dist/vue-router";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, reactive, ref, watch, onMounted, onBeforeMount} from 'vue';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_ALL_BADGE_LIST, ACT_GET_COM_BADGE_TY_LIST,ACT_GET_CHK_HIDDEN_BADGE} from '@/store/modules/badge/badge';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import IntegratedBadge from '@/components/common/IntegratedBadge';
import LoadingDiv from '@/components/common/LoadingDiv';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import SSLBadgeViewModal from '@/components/ssl/badge/mobile/SSLBadgeViewModal.vue';
import {goBackFunc} from '@/assets/js/modules/common/common';
import {getHrdBadgeList} from "@/assets/js/modules/hrd/hrd-badge";
import store from "@/store";
import {MUT_SHOW_HRD_BADGE} from "@/store/modules/hrdcloud/hrdcloud";


export default {
  name: 'BadgeBox',
  components: {SSLBadgeViewModal, LxpMobileHeader, LoadingDiv, IntegratedBadge, HistoryYearSelector, LxpMainHeader},
  setup() {
    const route = useRoute();
    const isMobile = computed(() => navigationUtils.any());
    const isLoading = ref(true);
    const currentDate = new Date();
    const yearStr = ref(route.query.year||currentDate.getFullYear().toString());
    const currentTimestamp = currentDate.getTime();
    const division = ref('');

    const sslHrdBadgesTmp = ref([]);

    // const sslHrdBadges = ref([]);
    // getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null);



    // console.lo

    // getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, sslHrdBadgesTmp, null,()=>{

    const sslHrdBadges = computed(() => {

      if(division.value === 'ssl'){
        return sslHrdBadgesTmp.value.filter(x => x.badgeKindCdDcd === "2065001" && x.year === yearStr.value ).sort((a,b)=>(b.badgeKindCdDcd -  a.badgeKindCdDcd)).sort((a,b) => {
          if (a.badgeTySn < b.badgeTySn) { return -1; }
          else {return 1;}
        });
        // return sslBadges[year.value - 2022].filter(x => !x.showYears || x.showYears.includes((yearStr.value + "")));
      }else if(division.value === 'hrdcloud'){
        return sslHrdBadgesTmp.value.filter(x =>  x.badgeKindCdDcd === "2065002" && x.year === yearStr.value).sort((a,b)=>(b.badgeKindCdDcd -  a.badgeKindCdDcd));
      } else {
        return sslHrdBadgesTmp.value.filter(x => x.year === yearStr.value ).sort((a,b)=> (b.badgeKindCdDcd -  a.badgeKindCdDcd) - (b.badgeTyCdDcd -  a.badgeTyCdDcd)).sort((a,b) => {
          if (a.badgeTySn < b.badgeTySn) { return -1; }
          else {return 1;}
        });
      }
    });
    const badges = ref([]);
    const paging = reactive({pageNo: 1, pageSize: 120, totalCount: 0});
    const toggle = ref(false);

    const checkActive = (badgeTyCdDcd) => {
      let res = badges.value.filter(x => x.badgeTyCdDcd === badgeTyCdDcd);
      return res && res.length > 0;
    };

    const checkNew = (badgeTyCdDcd) => {
      let res = badges.value.filter(x => x.badgeTyCdDcd === badgeTyCdDcd);
      return res && res.length > 0 && res[0].getDt + (1000 * 60 * 60 * 24 * 7) >= currentTimestamp;
    }

    const selectedDivision = computed(() => {
      if (division.value === 'ssl') return 'SSL';
      else if (division.value === 'hrdcloud') return 'HRD 클라우드';
      else return '전체'
    });

    const checkHiddenBadge = async ()=>{
      store.dispatch(`badge/${ACT_GET_CHK_HIDDEN_BADGE}`, {
      }).then( async res => {
        if (res.badges && res.badges.length > 0) {
          store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`, res.badges);
          await getHrdBadgeList();
        }
      });
    }

    const pagingFunc = () => {
      // if (pageNo > 0) paging.pageNo = pageNo;
      getListFunc(`badge/${ACT_GET_ALL_BADGE_LIST}`, {
        division: division.value,
        crsePrfx1: yearStr.value,
        ...paging
      }, badges, paging, () => {
        isLoading.value = false;
      }, () => {
        isLoading.value = false;
      });
    };



    const handleSelectDivision = (div) => {
      division.value = div;
      toggle.value = false;
      pagingFunc();
    };

    const badgeModal = ref(false);
    const selectedBadge = ref(null);
    const selectedBadgeType = ref('');
    const showBadgeModal = (badge) => {
      if (isMobile.value) {
        selectedBadge.value = badge;
        badgeModal.value = true;
        selectedBadgeType.value = badge.division;
      }
    };


    watch(() =>  route.query, async () => {
      await pagingFunc();
      yearStr.value = route.query.year;

    });
    onBeforeMount(async () => {
      await getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {}, sslHrdBadgesTmp, null);
      await pagingFunc();
    });

    onMounted(async () => {
      await checkHiddenBadge();
    });

    return {
      isMobile, isLoading, badges, paging, pagingFunc, yearStr, toggle, division, selectedDivision,
      sslHrdBadges, checkActive, handleSelectDivision,
      badgeModal, selectedBadge,
      showBadgeModal,
      selectedBadgeType,
      goBackFunc,
      checkNew
    }
  }
};
</script>
